var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"relative"},[_c('div',{staticClass:"absolute left-0 w-full top-1/2 transform -translate-y-1/2 opacity-0 transition-all duration-700 ease-in-out scale-0 flex items-center flex-col",class:{ 'opacity-100 scale-100': _vm.contactForm.successfullySent }},[_c('Icon',{staticClass:"text-contact-icon",attrs:{"name":"HappyRacoon"}}),_vm._v(" "),_c('h3',{staticClass:"text-center font-alisha text-5xl lg:text-6xl text-white"},[_vm._v("Vielen Dank für Deine Nachricht")]),_vm._v(" "),_c('p',{staticClass:"font-light text-center text-base"},[_vm._v("Ich werde mich schnellstmöglich um Dein Anliegen kümmern.")])],1),_vm._v(" "),_c('div',{staticClass:"absolute left-0 w-full top-1/2 transform -translate-y-1/2 transition-all duration-700 ease-in-out flex items-center flex-col z-10 opacity-0 scale-0",class:{ 'opacity-100 scale-100': _vm.contactForm.sentFailed }},[_c('Icon',{staticClass:"text-contact-icon",attrs:{"name":"SadRacoon"}}),_vm._v(" "),_c('h3',{staticClass:"text-center font-alisha text-5xl lg:text-6xl text-white"},[_vm._v("Hoppla, da ist was schief gegangen.")]),_vm._v(" "),_vm._m(0)],1),_vm._v(" "),_c('form',{staticClass:"transition-all duration-500 ease-in-out transform grid lg:grid-cols-2 gap-6",class:{ 'opacity-0 scale-0': _vm.contactForm.successfullySent || _vm.contactForm.sentFailed },attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.submit()}}},[_c('div',{ref:"contactFormEmail",staticClass:"lg:col-span-1 dark:focus-within:text-white lg:w-full"},[_vm._m(1),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contactForm.email.value),expression:"contactForm.email.value"}],staticClass:"text-gray-700 dark:bg-gray-500 dark:text-white rounded-md block w-full font-light py-3 px-3 focus:outline-none text-base mt-2 border-4 transition-colors duration-200 ease-in-out",class:{
          'border-red-400': _vm.contactForm.submitted && _vm.contactForm.email.errors.length,
          'dark:border-gray-600':
            !_vm.contactForm.submitted || (_vm.contactForm.submitted && !_vm.contactForm.email.errors.length),
        },attrs:{"id":"contactFormEmail","type":"email","required":""},domProps:{"value":(_vm.contactForm.email.value)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.contactForm.email, "value", $event.target.value)}}})]),_vm._v(" "),_c('div',{ref:"contactFormName",staticClass:"lg:col-span-1 dark:focus-within:text-white lg:w-full"},[_c('label',{staticClass:"uppercase font-bold text-xs tracking-widest transition-colors duration-200",attrs:{"for":"contactFormName"}},[_vm._v("\n        Dein Name\n      ")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contactForm.name.value),expression:"contactForm.name.value"}],staticClass:"text-gray-700 dark:bg-gray-500 dark:text-white rounded-md block w-full font-light py-3 px-3 focus:outline-none text-base mt-2 border-4 dark:border-gray-600 transition-colors duration-200 ease-in-out",attrs:{"id":"contactFormName","type":"text"},domProps:{"value":(_vm.contactForm.name.value)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.contactForm.name, "value", $event.target.value)}}})]),_vm._v(" "),_c('div',{ref:"contactFormSubject",staticClass:"lg:col-span-2 dark:focus-within:text-white"},[_vm._m(2),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contactForm.subject.value),expression:"contactForm.subject.value"}],staticClass:"text-gray-700 dark:bg-gray-500 dark:text-white rounded-md block w-full font-light py-3 px-3 focus:outline-none text-base mt-2 border-4 transition-colors duration-200 ease-in-out",class:{
          'border-red-400': _vm.contactForm.submitted && _vm.contactForm.subject.errors.length,
          'dark:border-gray-600':
            !_vm.contactForm.submitted || (_vm.contactForm.submitted && !_vm.contactForm.subject.errors.length),
        },attrs:{"id":"contactFormSubject","type":"text","required":""},domProps:{"value":(_vm.contactForm.subject.value)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.contactForm.subject, "value", $event.target.value)}}})]),_vm._v(" "),_c('div',{ref:"contactFormMessage",staticClass:"lg:col-span-2 dark:focus-within:text-white"},[_vm._m(3),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.contactForm.message.value),expression:"contactForm.message.value"}],staticClass:"text-gray-700 dark:bg-gray-500 dark:text-white rounded-md block w-full font-light py-3 px-3 focus:outline-none text-base mt-2 border-4 transition-colors duration-200 ease-in-out resize-none h-56",class:{
          'border-red-400': _vm.contactForm.submitted && _vm.contactForm.message.errors.length,
          'dark:border-gray-600':
            !_vm.contactForm.submitted || (_vm.contactForm.submitted && !_vm.contactForm.message.errors.length),
        },attrs:{"id":"contactFormMessage","required":""},domProps:{"value":(_vm.contactForm.message.value)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.contactForm.message, "value", $event.target.value)}}})]),_vm._v(" "),_c('div',{staticClass:"lg:col-span-2 gap-6 flex flex-col lg:flex-row lg:justify-between"},[_c('div',{ref:"contactFormPrivacyDisclaimerAccepted",staticClass:"flex items-center gap-4"},[_c('div',{staticClass:"relative h-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contactForm.privacyDisclaimerAccepted.value),expression:"contactForm.privacyDisclaimerAccepted.value"}],staticClass:"rounded bg-white dark:bg-gray-500 border-4 appearance-none h-8 w-8 text-gray-400 transition-colors duration-200 ease-in-out cursor-pointer",class:{
              'border-red-400': _vm.contactForm.submitted && _vm.contactForm.privacyDisclaimerAccepted.errors.length,
              'dark:border-gray-600':
                !_vm.contactForm.submitted ||
                (_vm.contactForm.submitted && !_vm.contactForm.privacyDisclaimerAccepted.errors.length),
            },attrs:{"id":"contactFormPrivacyDisclaimerAccepted","type":"checkbox","name":"privacyDisclaimerAccepted","required":""},domProps:{"checked":Array.isArray(_vm.contactForm.privacyDisclaimerAccepted.value)?_vm._i(_vm.contactForm.privacyDisclaimerAccepted.value,null)>-1:(_vm.contactForm.privacyDisclaimerAccepted.value)},on:{"change":function($event){var $$a=_vm.contactForm.privacyDisclaimerAccepted.value,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.contactForm.privacyDisclaimerAccepted, "value", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.contactForm.privacyDisclaimerAccepted, "value", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.contactForm.privacyDisclaimerAccepted, "value", $$c)}}}}),_vm._v(" "),_c('svg',{staticClass:"h-6 w-6 absolute select-none pointer-events-none dark:stroke-white stroke-gray-700",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M5 13l4 4L19 7"}})])]),_vm._v(" "),_c('label',{staticClass:"uppercase font-bold text-xs tracking-widest",attrs:{"for":"contactFormPrivacyDisclaimerAccepted"}},[_vm._v("\n          Ich akzeptiere die\n          "),_c('router-link',{staticClass:"dark:text-gray-400 dark:hover:text-gray-300 transition-colors underline",attrs:{"to":{ path: '/datenschutz/' },"target":"_blank"}},[_vm._v("\n            Datenschutzbestimmungen\n          ")]),_vm._v(" "),_c('span',{staticClass:"text-red-400"},[_vm._v("*")])],1)]),_vm._v(" "),_c('div',{staticClass:"lg:flex lg:justify-end lg:col-span-1"},[_c('SharedButton',{staticClass:"w-full lg:w-auto",attrs:{"data":{ size: 'large', label: 'Absenden' },"loading":_vm.loading,"type":"submit"}})],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',{staticClass:"font-light text-center text-base"},[_vm._v("\n      Bitte versuche es erneut oder sende mir eine\n      "),_c('a',{staticClass:"font-semibold hover:text-gray-100",attrs:{"href":"mailto:hello@alisha-coaching.de"}},[_vm._v("E-Mail")]),_vm._v(".\n    ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('label',{staticClass:"uppercase font-bold text-xs tracking-widest transition-colors duration-200",attrs:{"for":"contactFormEmail"}},[_vm._v("\n        Deine E-Mail"),_c('span',{staticClass:"text-red-400"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('label',{staticClass:"uppercase font-bold text-xs tracking-widest transition-colors duration-200",attrs:{"for":"contactFormSubject"}},[_vm._v("\n        Betreff"),_c('span',{staticClass:"text-red-400"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('label',{staticClass:"uppercase font-bold text-xs tracking-widest transition-colors duration-200",attrs:{"for":"contactFormMessage"}},[_vm._v("\n        Deine Nachricht"),_c('span',{staticClass:"text-red-400"},[_vm._v("*")])])
}]

export { render, staticRenderFns }