import { render, staticRenderFns } from "./Contact.vue?vue&type=template&id=602ecdb7&"
import script from "./Contact.vue?vue&type=script&lang=ts&"
export * from "./Contact.vue?vue&type=script&lang=ts&"
import style0 from "./Contact.vue?vue&type=style&index=0&id=602ecdb7&prod&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/vercel/path0/components/Icon.vue').default,SharedButton: require('/vercel/path0/components/shared/Button.vue').default})
