
import { Component, Ref, Vue, Watch } from 'nuxt-property-decorator'
import createContactRequestMutation from '~/graphql/mutations/createContactRequest.graphql'

@Component
export default class Contact extends Vue {
  firstElementWithError: HTMLElement | null = null
  loading: boolean = false

  get contactForm() {
    return this.$store.state.contactForm
  }

  @Ref()
  readonly contactFormEmail!: HTMLDivElement

  @Ref()
  readonly contactFormName!: HTMLDivElement

  @Ref()
  readonly contactFormSubject!: HTMLDivElement

  @Ref()
  readonly contactFormMessage!: HTMLDivElement

  @Ref()
  readonly contactFormPrivacyDisclaimerAccepted!: HTMLDivElement

  @Watch('contactForm.email.value')
  emailChanged(value: string, oldValue: string) {
    if (value !== oldValue) {
      this.contactForm.email.errors = []
    }
  }

  @Watch('contactForm.subject.value')
  subjectChanged(value: string, oldValue: string) {
    if (value !== oldValue) {
      this.contactForm.subject.errors = []
    }
  }

  @Watch('contactForm.privacyDisclaimerAccepted.value')
  privacyDisclaimerAcceptedChanged(value: string, oldValue: string) {
    if (value !== oldValue) {
      this.contactForm.privacyDisclaimerAccepted.errors = []
    }
  }

  @Watch('contactForm.message.value')
  messageChanged(value: string, oldValue: string) {
    if (value !== oldValue) {
      this.contactForm.message.errors = []
    }
  }

  async resetValues() {
    this.contactForm.name.value = null
    this.contactForm.email.value = null
    this.contactForm.subject.value = null
    this.contactForm.message.value = null
    this.contactForm.privacyDisclaimerAccepted.false = null
  }

  async resetErrors() {
    this.firstElementWithError = null
    this.contactForm.name.errors = []
    this.contactForm.email.errors = []
    this.contactForm.subject.errors = []
    this.contactForm.message.errors = []
    this.contactForm.privacyDisclaimerAccepted.errors = []
  }

  async valid() {
    let is_valid = true
    await this.resetErrors()
    const emailRegExp =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
    if (!this.contactForm.email.value || !emailRegExp.test(this.contactForm.email.value)) {
      this.contactForm.email.errors.push('Bitte gebe Deine E-Mail-Adresse ein.')
      this.firstElementWithError = this.contactFormEmail as HTMLDivElement
      is_valid = false
    } else {
      this.contactForm.email.errors = []
    }
    if (!this.contactForm.subject.value) {
      this.contactForm.subject.errors = []
      this.contactForm.subject.errors.push('Bitte gebe einen Betreff ein.')
      is_valid = false
      if (!this.firstElementWithError) {
        this.firstElementWithError = this.contactFormSubject as HTMLDivElement
      }
    } else {
      this.contactForm.subject.errors = []
    }
    if (!this.contactForm.message.value) {
      this.contactForm.message.errors.push('Bitte gebe eine Nachricht ein.')
      is_valid = false
      if (!this.firstElementWithError) {
        this.firstElementWithError = this.contactFormMessage as HTMLDivElement
      }
    } else {
      this.contactForm.message.errors = []
    }
    if (!this.contactForm.privacyDisclaimerAccepted.value) {
      this.contactForm.privacyDisclaimerAccepted.errors.push('Bitte akzeptiere die Datenschutzbestimmungen.')
      is_valid = false
      if (!this.firstElementWithError) {
        this.firstElementWithError = this.contactFormPrivacyDisclaimerAccepted as HTMLDivElement
      }
    } else {
      this.contactForm.privacyDisclaimerAccepted.errors = []
    }
    return is_valid
  }

  async submit() {
    this.contactForm.submitted = true
    if (!(await this.valid())) {
      this.firstElementWithError.scrollIntoView({ behavior: 'smooth' })
      setTimeout(() => {
        this.$store.commit('setIsSticky', false)
      }, 300)
      return
    }
    this.loading = true
    const { errors } = await this.$apollo.mutate({
      mutation: createContactRequestMutation,
      variables: {
        name: this.contactForm.name.value,
        email: this.contactForm.email.value,
        subject: this.contactForm.subject.value,
        message: this.contactForm.message.value,
        privacyDisclaimerAccepted: this.contactForm.privacyDisclaimerAccepted.value,
      },
    })
    this.loading = false
    if (errors) {
      await this.handleFailure()
      return
    }
    await this.handleSuccess()
  }

  async handleSuccess() {
    this.contactForm.successfullySent = true
    await this.resetValues()
    setTimeout(() => {
      this.contactForm.successfullySent = false
    }, 10000)
  }

  async handleFailure() {
    this.contactForm.sentFailed = true
    setTimeout(() => {
      this.contactForm.sentFailed = false
    }, 10000)
  }
}
